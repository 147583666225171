import React, { useState, useEffect, useRef, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'
import { getAppMode, setAppMode, getUser } from '../services/auth'
import Popup from 'reactjs-popup'
import credits from './assets/credits.svg'

import themeStyles from './styles/theme.module.scss'
import CreditsModal from './creditsModal'

export default function Theme () {
  const [creditAvailable, setCreditAvailable] = useState(0)
  const [isDarkMode, setIsDarkMode] = useState(true)
  const ref = useRef()

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode)
  }

  useEffect(() => {
    const _user = getUser()
    if (_user._id) {
      setCreditAvailable(_user.credit.available)
    }
  }, [])

  const closeTooltip = useCallback(() => {
    ref.current.close()
  }, [ref])

  // Set the last mode stored in cache
  useEffect(() => {
    const appMode = getAppMode()
    if (!appMode || appMode === 'Dark') {
      setAppMode('Dark')
      setIsDarkMode(true)
    } else if (appMode === 'Light') {
      setIsDarkMode(false)
    }
  }, [])
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.style.setProperty('--primary-color', 'rgb(0, 0, 0)')
      document.documentElement.style.setProperty('--secondary-color', '#fff')
      document.documentElement.style.setProperty('--tertiary-color', '#3b9f6f34')
      document.documentElement.style.setProperty('--quaternary-color', 'rgba(255, 255, 255, 0.4)')
      setAppMode('Dark')
    } else {
      document.documentElement.style.setProperty('--primary-color', 'rgb(247, 244, 244)')
      document.documentElement.style.setProperty('--secondary-color', 'rgb(26, 24, 24)')
      document.documentElement.style.setProperty('--tertiary-color', '#3b9f6fe7')
      document.documentElement.style.setProperty('--quaternary-color', '#333')
      setAppMode('Light')
    }
  }, [isDarkMode])

  return (
    <div className={themeStyles.container}>
      <Popup
        ref={ref}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        trigger={
          <div className={themeStyles.creditSection}>
            <strong>{creditAvailable}</strong>
            <img className={themeStyles.creditsBtn} src={credits} alt='credits icon' />
          </div>
    }
        p
        modal
      >
        <span>
          <CreditsModal close={closeTooltip} onChangeCredit={setCreditAvailable} />
        </span>
      </Popup>
      <p onClick={toggleDarkMode}>
        <FontAwesomeIcon
          className={themeStyles.themeBtn}
          icon={isDarkMode ? faSun : faMoon}
        />
      </p>
    </div>
  )
}

import DownloadsTable from '../components/downloadsTable'

import downloadsStyles from './styles/downloads.module.scss'

export default function Download () {
  return (
    <>
      <div className={downloadsStyles.container}>
        <h1>Redeemed NFTs Files</h1>
        <DownloadsTable />
      </div>
    </>
  )
}

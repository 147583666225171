import React, { useRef } from 'react'
import Popup from 'reactjs-popup'
import { Link } from 'react-router-dom'
import BotInfo from '../components/botInfo'
import AccordionFaq from '../components/accordion'
import telegram from '../components/assets/telegram.svg'
import bot from '../components/assets/bot.svg'
import info from '../components/assets/question.svg'

import faqStyles from './styles/faq.module.scss'

export default function Faq () {
  const ref = useRef()
  const closeTooltip = () => {
    ref.current.close()
  }
  return (
    <div className={faqStyles.container}>
      <div className={faqStyles.faqLinkSection}>
        <h2>Get in touch with our team</h2>
        <Link className={faqStyles.linkStyle} to='/contact'>
          <button>Contact Us</button>
        </Link>
      </div>
      <div className={faqStyles.telegramBotSection}>
        <div className={faqStyles.iconsSection}>
          <img className={faqStyles.pulse} src={telegram} alt='telegram icon' />
          <img className={faqStyles.bot} src={bot} alt='telegram icon' />
        </div>
        <div className={faqStyles.infoSection}>
          <h2>TokenTiger Telegram Bot</h2>
          <p>
            Introducing TokenTiger Bot, a Telegram bot for creating NFTs within
            your favorite channels! This innovative bot integrates with your
            Telegram experience, allowing users to tokenize images with just a
            simple reply. Join our Telegram Channel to try it out!
          </p>
          <a
            href='https://t.me/+OEzx9kpde2llNTAx'
            target='_blank'
            without
            rel='noreferrer'
            className={faqStyles.joinBtn}
          >
            Join TokenTiger Telegram Channel
          </a>
        </div>
        <div>
          <Popup
            ref={ref}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            trigger={
              <span className={faqStyles.about}>
                {' '}
                <img src={info} alt='about this bot' /> More info
              </span>
            }
            p
            modal
          >
            <span>
              <BotInfo close={closeTooltip} />
            </span>
          </Popup>
        </div>
      </div>

      <h1>Frequently Asked Questions</h1>

      <AccordionFaq />
    </div>
  )
}

import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import buyCreditModalStyles from './styles/buyCreditsModal.module.scss'
import copy from './assets/copy.svg'
import { getUser } from '../services/auth'
import {
  cancelPayment,
  getPaymentAddress,
  getPayments,
  getPaymentUser,
  deletePayment,
  createPayment
} from '../services/payment'
import QRCode from 'react-qr-code'
import { toast } from 'react-toastify'
import PropagateLoader from 'react-spinners/PropagateLoader'

import Countdown, { zeroPad, calcTimeDelta } from 'react-countdown'

// CountDown component
const timerView = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // do something
  } else {
    return (
      <h3 style={{ color: 'white' }}>
        Time Left:{' '}
        <strong>
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </strong>
      </h3>
    )
  }
}

const CountdownTime = 60000 * 10 // 10 minutes

export default function BuyCreditsModal (props) {
  const { close, onSuccess, type } = props
  const [address, setAddress] = useState('')
  const [paymentDetected, setPaymentDetected] = useState(false)
  const [lastPaymentDetected, setLastPaymentDetected] = useState(false)
  const [payment, setPayment] = useState({})
  const [extendedAddress, setExtendedAddress] = useState()
  const [qrValue, setQrValue] = useState()

  // If no more payments are detected, reload credits.
  useEffect(() => {
    if (onSuccess && lastPaymentDetected && !paymentDetected) {
      try {
        onSuccess()
        close()
      } catch (error) {
        // skip
      }
    }
  }, [lastPaymentDetected, paymentDetected, onSuccess, close])

  useEffect(() => {
    if (address && payment) {
      const extendedAddress = `${address}?amount=${
        payment.priceSats / 10 ** 8
      }`
      setExtendedAddress(extendedAddress)
      setQrValue(address)
    }
  }, [address, payment])

  // Load payment status .
  const loadAddress = useCallback(async (i) => {
    try {
      const user = getUser()
      const res = await getPaymentAddress({ id: user._id })
      const addressData = res.data
      setAddress(addressData.address)

      // Validate if a payment has been detected
      const paymentDetected =
        addressData.lastPaymentTime > addressData.lastReviewTime

      /*       // Development purpose
      if (i > 2 && i < 5) { paymentDetected = true }
      if (i >= 5) { paymentDetected = false } */

      if (paymentDetected) {
        setLastPaymentDetected(Date.now())
      }

      console.log('payment detected', paymentDetected)
      setPaymentDetected(paymentDetected)
    } catch (error) {
      console.warn(error)
    }
  }, [])

  const tryToCreateNewPayment = useCallback(async () => {
    try {
      const paymentUser = getPaymentUser()
      const paymentsRes = await getPayments()
      const payments = paymentsRes.payments
      console.log('payments', payments)

      const filter = payments.filter((val, i) => {
        return val.status === 'in-process' && val.userId === paymentUser._id
      })
      if (filter.length) {
        const payment = filter[0]
        const createdAt = new Date(payment.createdAt)
        const timeDelta = calcTimeDelta(createdAt, {
          offsetTime: CountdownTime
        })
        if (timeDelta.completed) {
          await cancelPayment(payment._id)
        } else {
          setPayment(filter[0])
          toast.info(
            'Dear user, you must complete/cancel the current payment in process'
          )
          return
        }
      }

      const user = getPaymentUser()
      console.log(user)
      const paymentRes = await createPayment({ userId: user._id, type })
      setPayment(paymentRes.payment)
      console.log('payment created', paymentRes.payment)
    } catch (error) {
      console.warn(error)
      toast.error(error.message)
    }
  }, [type])

  // Component Mount.
  // Start to load address status from the server every time interval.
  useEffect(() => {
    if (!payment._id) {
      tryToCreateNewPayment()
      return
    }
    loadAddress()
    let i = 0 // Development purposes
    const reviewTimer = setInterval(() => {
      loadAddress(i)
      i++
    }, 5000)

    return () => {
      // Component Unmount.
      // Clear interval  Once the modal close
      clearInterval(reviewTimer)
    }
  }, [payment, loadAddress, tryToCreateNewPayment])

  // get address from server
  useEffect(() => {}, [])

  // Copy address to clipboard
  const copyToClipboard = (address) => {
    window.navigator.clipboard.writeText(address)
    toast.success('Copied to clipboard!')
  }

  const handleCancel = () => {
    try {
      close()
      deletePayment(payment._id)
    } catch (error) {}
  }

  const handleTimeout = () => {
    try {
      toast.info('Dear user, Payment Timeout. Payment Cancelled.')
      close()
      cancelPayment(payment._id)
    } catch (error) {}
  }

  const handleToggle = (e) => {
    const checked = e.target.checked
    if (!checked) {
      setQrValue(address)
      console.log(`Selected Address ${address}`)
    } else {
      setQrValue(extendedAddress)
      console.log(`Selected Address ${extendedAddress}`)
    }
  }
  return (
    <div className={buyCreditModalStyles.container}>
      <h2>Buy TokenTiger Credits</h2>
      {address && (
        <p>
          Send{' '}
          <strong>
            {payment.priceSats / 10 ** 8} BCH ( ${payment.priceUSD} )
          </strong>{' '}
          to this address to buy TokenTiger Credits, you can scan the QR code or
          copy the address below. <br />{' '}
          <strong> {payment.creditsQuantity} Credits</strong> will be added to
          your account. <br />
          <Link className={buyCreditModalStyles.link} to='/terms' target='__blank'>
            <span>Term and Conditions</span>
          </Link>
        </p>
      )}
      {address && extendedAddress && (
        <div>
          <div className={buyCreditModalStyles.qrCodeWrapper}>
            <QRCode
              size={160}
              style={{ margin: '1em' }}
              value={qrValue}
              viewBox='0 0 256 256'
            />
          </div>

          <div className={buyCreditModalStyles.toggleQR}>
            <input
              type='checkbox'
              id='normalQR'
              name='normalQR'
              onChange={handleToggle}
            />
            <label htmlFor='normalQR'>Toggle</label>
            <span>
              Use QR Code that includes exact payment amount <br /> (may not
              work with all wallets)
            </span>
          </div>

          <div className={buyCreditModalStyles.addrWrapper}>
            <span>{address} </span>
            <img
              src={copy}
              alt='copy button'
              onClick={() => copyToClipboard(address)}
            />
          </div>

          <div className={buyCreditModalStyles.paymentInfo}>
            <Countdown
              zeroPadTime={2}
              renderer={timerView}
              date={payment.createdAt + CountdownTime}
              onComplete={handleTimeout}
            />
            <div className={buyCreditModalStyles.bar} />
            <h3>
              Total Price: <strong>${payment.priceUSD}</strong>{' '}
            </h3>
          </div>
        </div>
      )}

      {paymentDetected && (
        <>
          <div>
            <PropagateLoader
              color='rgb(59, 159, 111)'
              loading={paymentDetected}
              size={7}
              cssOverride={{
                display: 'block',
                textAlign: 'center'
              }}
              speedMultiplier={1}
              style={{ position: 'absolute', bottom: '20%', left: '50%' }}
            />
          </div>
          <span style={{ color: 'white', height: '100px', marginTop: '1em' }}>
            {' '}
            Payment currently in process!
          </span>
        </>
      )}
      {address && (
        <button
          className={buyCreditModalStyles.closeBtn}
          onClick={handleCancel}
        >
          Cancel
        </button>
      )}

      {!address && (
        <div>
          <PropagateLoader
            color='rgb(59, 159, 111)'
            loading={!address}
            size={7}
            cssOverride={{
              display: 'block',
              textAlign: 'center'
            }}
            speedMultiplier={1}
            style={{ position: 'absolute', top: '60%', left: '50%' }}
          />
        </div>
      )}
    </div>
  )
}

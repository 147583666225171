import React, { useState } from 'react'

import LoginForm from '../components/loginForm'
import LoginInfo from '../components/loginInfo'

// import logo from '../components/assets/logo.png'
import loginStyles from './styles/login.module.scss'
import ClickWrap from '../components/clickwrap'
import Popup from 'reactjs-popup'
import { useNavigate } from 'react-router-dom'
import { getUser } from '../services/auth'

export default function Login (props) {
  const navigate = useNavigate()
  const [showTos, setShowTos] = useState(false)
  const [tosVersion, setTosVersion] = useState(null)

  // Login handler
  const onSuccess = (version) => {
    const user = getUser()

    if (user.tosVersion !== version) {
      setShowTos(true)
      setTosVersion(version)
    } else {
      navigate('/gallery')
    }
  }

  // Modal Info handler
  const closeModal = () => {
    navigate('/gallery')
  }
  return (
    <div className={loginStyles.container}>
      {!showTos &&
        <>
          <div className={loginStyles.login}>
            <h1 className={loginStyles.logoText}>
              TokenTiger <span className={loginStyles.tm}>&trade;</span>{' '}
            </h1>
            <LoginForm {...props} onSuccess={onSuccess} />
          </div>
          <div className={loginStyles.info}>
            <LoginInfo />
          </div>
        </>}
      <Popup
        closeOnDocumentClick={false}
        closeOnEscape={false}
        /*      ref={ref} */
        open={showTos}
        p
        modal
      >
        <ClickWrap onClose={closeModal} tosVersion={tosVersion} />
      </Popup>
    </div>
  )
}

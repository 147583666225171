import termStyles from './styles/terms.module.scss'

export default function Terms () {
  return (
    <div className={termStyles.container}>
      <h1>Terms and Conditions</h1>
      <div className={termStyles.bar} />

      <div className={termStyles.textSection}>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          odio, unde nulla, placeat ea necessitatibus asperiores minima
          accusantium doloribus reprehenderit possimus dolorem sed tenetur hic
          aspernatur ipsa, rerum dicta quo! unde nulla, placeat ea
          necessitatibus asperiores minima accusantium doloribus reprehenderit
          possimus dolorem sed tenetur hic aspernatur ipsa, rerum dicta quo!
        </p>

        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          odio, unde nulla, placeat ea necessitatibus asperiores minima
          accusantium doloribus reprehenderit possimus dolorem sed tenetur hic
          aspernatur ipsa, rerum!
        </p>

        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          odio, unde nulla, placeat ea necessitatibus asperiores minima
          accusantium doloribus reprehenderit possimus dolorem sed tenetur hic
          aspernatur ipsa, rerum dicta quo! unde nulla, placeat ea
          necessitatibus.
        </p>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'

import clickwrapStyles from './styles/clickwrap.module.scss'
import PropagateLoader from 'react-spinners/PropagateLoader'
import { getToSContent, updateUser } from '../services/user'
import { getUser } from '../services/auth'

export default function ClickWrap (props) {
  const [content, setContent] = useState('')
  const [inFetch, setInFetch] = useState(true)

  useEffect(() => {
    const loadContent = async () => {
      const contentRes = await getToSContent()
      setContent(contentRes)
      setInFetch(false)
    }

    loadContent()
  }, [])

  const submit = async () => {
    try {
      const user = getUser()
      const dataToUpdate = {
        _id: user._id,
        tosVersion: props.tosVersion
      }
      setInFetch(true)
      await updateUser(dataToUpdate)
      setInFetch(false)

      if (props.onClose) props.onClose()
    } catch (error) {
      setInFetch(false)
    }
  }
  return (
    <div className={clickwrapStyles.container}>
      <div className={clickwrapStyles.header}>
        <h1 className={clickwrapStyles.logoText}>
          TokenTiger <span className={clickwrapStyles.tm}>&trade;</span>
        </h1>
      </div>
      <div className={clickwrapStyles.content}>

        {!inFetch && content && (
          <>
            <h3>
              Welcome to{' '}
              <span className={clickwrapStyles.boldText}>Token Tiger! &trade;</span>
            </h3>
            <p>
              <div dangerouslySetInnerHTML={{ __html: content }} />

            </p>
          </>
        )}
        {inFetch && (
          <PropagateLoader
            color='#ffffff'
            loading={inFetch}
            size={5}
            cssOverride={{
              display: 'block',
              textAlign: 'center'
            }}
            speedMultiplier={1}
          />
        )}
      </div>
      <div className={clickwrapStyles.bottom}>
        {!inFetch && content && (
          <button
            className={clickwrapStyles.acceptBtn}
            onClick={submit}
          >
            OK
          </button>
        )}
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import downloadStyles from './styles/download.module.scss'

// Assets
import burnStyles from './styles/burn.module.scss'
import fire from './assets/fire.svg'
import icon from './assets/download2.svg'

// Services
import { burnProjectToken, burnUserToken } from '../services/token'
import { exportSharableToken } from '../services/project'
import { downloadMetadata } from '../services/payload'

// Spinner
import { motion } from 'framer-motion'

// Notification lib
import { toast } from 'react-toastify'

// Redux Functions
import actions from '../redux/actions'

const { useDispatch, deleteCollectionToken } = actions

// Send a token to this address to burn it
const BURN_ADDRESS = 'simpleledger:qqsrke9lh257tqen99dkyy2emh4uty0vkyflye6lwa'

export default function Download (props) {
  const { close, collection, token, selectedSection, tokenInfo } = props
  // const [txId, setTxId] = useState('')
  const [inFetch, setInFetch] = useState(false)
  const [successTxId, setTxId] = useState('')

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // handle burn in personal collection
  const burnFromProject = async () => {
    try {
      // show spinner
      setInFetch(true)

      // export token
      const txId = await burnProjectToken(collection._id, token.tokenId)
      if (!txId) {
        throw new Error('Token cant be burned')
      }
      // delete token from redux store ( delete token from view )
      dispatch(deleteCollectionToken({ collectionId: collection._id, token }))

      setTxId(txId)
      setInFetch(false)
      toast.success('Success!')
    } catch (error) {
      console.warn('Error in burn/burnFromProject()', error)
      setInFetch(false)
      toast.error('Error! : ' + error.message)
    }
  }
  // handle burn in user collection
  const burnFromUser = async () => {
    try {
      const collectionId = 'user-wallet-tokens'

      // show spinner
      setInFetch(true)

      // export token
      const txId = await burnUserToken(token.tokenId)
      if (!txId) {
        throw new Error('Token cant be burned')
      }
      // delete token from redux store ( delete token from view )
      dispatch(deleteCollectionToken({ collectionId, token }))

      setTxId(txId)
      setInFetch(false)
      toast.success('Success!')
      console.log('txId : ', successTxId)
    } catch (error) {
      console.warn('Error in burn/burnFromProject()', error)
      setInFetch(false)
      toast.error('Error! : ' + error.message)
    }
  }
  // handle burn in sharable collection
  const burnFromSharable = async () => {
    try {
      // show spinner
      setInFetch(true)

      // export token
      const txId = await exportSharableToken(
        token.tokenId,
        BURN_ADDRESS,
        collection.publicId
      )
      if (!txId) {
        throw new Error('Token cant be burned!')
      }

      // delete token from redux store ( delete token from view )
      const collectionId = collection.publicId
      dispatch(deleteCollectionToken({ collectionId, token }))

      setTxId(txId)
      setInFetch(false)
      toast.success('Success!')
    } catch (error) {
      console.warn('Error in burn/burnFromSharable()', error)
      setInFetch(false)
      toast.error('Error! : ' + error.message)
    }
  }

  const submit = async () => {
    if (selectedSection === 'collections') {
      setInFetch(true)
      await download()
      await burnFromProject()
      setInFetch(false)
      navigate('/downloads')
    } else if (selectedSection === 'sharable') {
      setInFetch(true)
      await download()
      await burnFromSharable()
      setInFetch(false)
      navigate('/downloads')
    } else if (selectedSection === 'user-wallet') {
      setInFetch(true)
      await download()
      await burnFromUser()
      navigate('/downloads')
    }
  }

  const download = async () => {
    try {
      const url = `https://pin.fullstack.cash/ipfs/view/${tokenInfo.mutableDataCid}/data.json`
      const res = await axios.get(url)

      const metadataId = res.data.payloadId
      const downloadRes = await downloadMetadata(metadataId)

      const urlCreator = window.URL || window.webkitURL
      const href = urlCreator.createObjectURL(downloadRes)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', metadataId)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <>
      <div className={downloadStyles.container}>
        {!inFetch && (
          <>
            <div className={downloadStyles.content}>
              <div className={downloadStyles.iconSection}>
                <h1>Download NFT file</h1>
                <img
                  className={downloadStyles.icon}
                  src={icon}
                  alt='download icon'
                />
              </div>

              <div className={downloadStyles.textSection}>
                <p>
                  Burn the NFT to unlock the associated file, revealing
                  exclusive content and gaining access to unique digital assets,
                  a new way to interact with your tokens!
                </p>
              </div>
            </div>
            <div className={downloadStyles.btnSection}>
              <button className={downloadStyles.closeBtn} onClick={close}>
                Close
              </button>
              <button className={downloadStyles.burnBtn} onClick={submit}>
                Redeem NFT
              </button>
            </div>
          </>
        )}
        {inFetch && (
          <motion.img
            dragSnapToOrigin='true'
            drag='x'
            dragTransition={{ bounceStiffness: 800, bounceDamping: 20 }}
            dragConstraints={{ left: 50, right: 50 }}
            animate={{
              scale: [1, 1.5, 1]
            }}
            transition={{
              repeat: Infinity,
              duration: 2
            }}
            className={burnStyles.fire}
            src={fire}
            alt='burning nft'
          />
        )}
      </div>
    </>
  )
}
